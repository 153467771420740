<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>语言学校</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form inline label-position="right" label-width="60px" size="small" class="query-form">
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <el-button type="success" @click="
            dialogVisible = true;
          form = { isEnable: true };
          selectedOptions = [];
          simglist = [];
          bimglist = [];
          dormlist = [];
          conditlist = [];
          videolist = [];
          ">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>语言学校</span>
        </div>
        <!-- 列表定义 -->
        <el-table v-loading="loading" :data="tableData" ref="tableData" border stripe style="width: 100%">
          <el-table-column prop="name" label="名称"></el-table-column>
          <!-- <el-table-column prop="oneWord" label="评语"></el-table-column> -->
          <el-table-column label="地区">
            <template slot-scope="scope">
              {{ scope.row.province }} {{ scope.row.city }}
            </template>
          </el-table-column>
          <el-table-column prop="studyFee" label="学费(万日元)"></el-table-column>
          <el-table-column prop="nature" label="学校性质"></el-table-column>
          <el-table-column prop="viewCount" label="浏览量"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="isEnable" label="是否展示">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isEnable == true">是</el-tag>
              <el-tag type="warn" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button type="primary" size="mini" icon="el-icon-edit" @click="editClick(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button type="warning" size="mini" icon="el-icon-delete" @click="delClick(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="学校新闻" placement="top">
                  <el-link :href="'./schoolnews?id=' + scope.row.id" target="_blank">
                    <el-button type="success" size="mini" icon="el-icon-news"></el-button>
                  </el-link>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage4" :page-sizes="[10, 20, 30, 50, 100]" :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 form表单对应编辑，弹出对话框-->
    <el-dialog :title="form.id > 0 ? '编辑语言学校' : '添加语言学校'" :visible.sync="dialogVisible" @opened="dialogOpened()">
      <el-form :model="form" ref="form" label-width="100px" :status-icon="true"
        @submit.native.prevent="submitForm('form')">
        <el-tabs type="card">
          <el-tab-pane label="基本信息">
            <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '请输入学校名称' }]">
              <el-input v-model="form.name" placeholder="请输入学校名称"></el-input>
            </el-form-item>
            <!-- 图片顶部 -->
            <el-form-item label="图片" prop="imgUrl" :rules="[{ required: true, message: '不能为空' }]">
              <div id="uploadBtn6" class="uploader uploader-warning"></div>
              <img id="relativeName" :src="domain + form.imgUrl"
                style="height: 40px; position: relative; left: 10px; top: 20px" v-if="form.imgUrl" />
              <span class="imgtext" title type="info"
                style="position: relative; left: 0px; top: 1px">*图片尺寸：推荐400*240</span>
            </el-form-item>
            <!-- 图片底部 -->
            <el-form-item label="视频地址">
              <div id="uploadBtn7" class="uploader uploader-warning"></div>
              <!-- v-for="(item,index) in videolist" :key="index" -->
              <div id="relativeName" style="margin-left: 20px" v-for="(item, index) in videolist" :key="index">
                <a :href="addDomainIfNeeded(item)" target="_blank">{{ addDomainIfNeeded(item) }}</a>
                <span style="color:red;font-size: 12px;padding-left: 10px;" @click="delhref(index)">删除</span>
              </div>
              <!-- <span
                class="imgtext"
                title
                type="info"
                style="position: relative; left: 0px; top: 1px"
                >*视频格式：mp4;视频最大不超过1G；</span
              > -->
            </el-form-item>


            <!-- 选择地区代码段  -->
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="地区" :rules="[{ required: true, message: '不能为空' }]">
                  <el-cascader :options="arealist" v-model="selectedOptions" clearable
                    :props="{ value: 'areaName', label: 'areaName' }" style="width: 100%"
                    placeholder="请选择地区"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="学校地址" prop="siteDetail" :rules="[{ required: true, message: '不能为空' }]">
              <el-input v-model="form.siteDetail" placeholder="请输入学校地址"></el-input>
            </el-form-item>

            <!-- <el-row :gutter="24">
              <el-col :span="10">
                <el-form-item
                  label="经度"
                  prop="longitude"
                  :rules="[
                    { required: false, message: '请选择坐标', trigger: 'blur' },
                    { required: true, message: '不能为空' },
                  ]"
                >
                  <el-input v-model="form.longitude"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label="纬度"
                  prop="latitude"
                  :rules="[
                    { required: false, message: '请选择坐标', trigger: 'blur' },
                    { required: true, message: '不能为空' },
                  ]"
                >
                  <el-input v-model="form.latitude"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button type="success" @click="dialogMap = true"
                  >选择位置</el-button
                >
              </el-col>
            </el-row> -->

            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="学费" prop="studyFee" :rules="[{ required: true, message: '不能为空' }]">
                  <el-input type="number" step="0.01" v-model="form.studyFee" placeholder="请输入学费">
                    <template slot="append">万日元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="学费2" prop="studyFeeT">
                  <el-input type="number" step="0.01" v-model="form.studyFeeT" placeholder="请输入学费2">
                    <template slot="append">万日元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="建校时间" prop="foundYear" :rules="[{ required: true, message: '不能为空' }]">
                  <el-input v-model.number="form.foundYear" placeholder="请输入建校时间">
                    <template slot="append">年</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="学校性质" prop="nature" :rules="[{ required: true, message: '不能为空' }]">
                  <el-select v-model="form.nature" placeholder="请选择学校性质" filterable style="width: 100%">
                    <el-option v-for="item in naturelist" :label="item.name" :value="item.name"
                      :key="item.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="定员" prop="fixedCount" :rules="[{ required: true, message: '不能为空' }]"
                  style="width: 100%">
                  <el-input v-model.number="form.fixedCount" placeholder="请输入定员">
                    <template slot="append">人</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="学生比例" prop="chinesePercent">
                  <el-input v-model.number="form.chinesePercent" placeholder="请输入中国学生比例">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入学时间" prop="monthList">
                  <el-select v-model="form.monthList" placeholder="请选择入学时间" filterable multiple style="width: 100%">
                    <el-option v-for="item in monthlist" :label="item.name" :value="item.id" :key="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 学校标签 -->
            <el-form-item label="学校标签" prop="labelList">
              <el-select v-model="form.labelList" placeholder="请选择学校标签" clearable filterable multiple
                style="width: 100%">
                <el-option v-for="item in labellist" :label="item.label" :value="item.label" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <!-- 学校标签底部 -->
            <el-form-item label="评语" prop="oneWord">
              <el-input v-model="form.oneWord" placeholder="请输入评语"></el-input>
            </el-form-item>

            <el-form-item label="学校周边" prop="ambitus">
              <el-input type="textarea" v-model="form.ambitus" placeholder="请输入学校周边"></el-input>
            </el-form-item>

            <el-form-item label="地图交通" prop="siteMap">
              <el-input type="textarea" v-model="form.siteMap" placeholder="请输入地图交通"></el-input>
            </el-form-item>

            <el-form-item label="排序字段" prop="sort">
              <el-input v-model.number="form.sort" placeholder="请输入排序字段"></el-input>
            </el-form-item>
            <!-- 是否可用顶部 -->
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item prop="isEnable" label="是否可用" :rules="[{ required: true, message: '不能为空' }]">
                  <el-switch v-model="form.isEnable"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>

          <!-- 学校组图 -->
          <el-tab-pane label="学校组图">
            <div class="uploader uploader-warning" id="uploadSchool1"></div>
            <el-row>
              <el-col :span="6" v-for="(item, index) in simglist" :key="index" style="padding: 5px">
                <el-card :body-style="{ padding: '0px' }">
                  <el-image :src="item" class="image" style="width: 100%; height: 100px"
                    :preview-src-list="bimglist"></el-image>
                  <!-- <img
                    :src="item"
                    class="image"
                    style="width: 100%"
                    :preview-src-list="bimglist"
                  /> -->
                  <div style="padding: 5px 14px 10px 14px">
                    <div class="bottom clearfix">
                      <el-button-group>
                        <el-tooltip content="前移" placement="top">
                          <el-button type="primary" icon="el-icon-arrow-left" size="mini"
                            @click="imgMove(index)"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除" placement="top">
                          <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="imgRemove(index)"></el-button>
                        </el-tooltip>
                      </el-button-group>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <div style="height: 10px"></div>
            <el-alert style="margin-bottom: 10px">
              <p>说明：上传图片宽度不小于500，上传图片数量不超过15张</p>
            </el-alert>
          </el-tab-pane>
          <!-- 学习介绍字段  -->
          <el-tab-pane label="学校介绍">
            <el-form-item label="学校介绍" prop="summary">
              <div style="position: relative">
                <vue-ueditor-wrap v-model="form.summary" :config="editorConfig"></vue-ueditor-wrap>
              </div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="官方简介">
            <el-form-item label="官方简介" prop="special">
              <div style="position: relative">
                <div class="btn-my">
                  <el-button size="mini" type="success" @click="addTitle()">自定义标题</el-button>
                </div>
                <vue-ueditor-wrap v-model="form.special" :config="editorConfig" @ready="editorReady"></vue-ueditor-wrap>
              </div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="学生反馈">
            <el-form-item label="学生反馈" prop="feedBack">
              <div style="position: relative">
                <vue-ueditor-wrap v-model="form.feedBack" :config="editorConfig"></vue-ueditor-wrap>
              </div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="申请条件">
            <el-button type="primary" @click="
              dialogCondition = true;
            conditParam = {};
            ">添加</el-button>
            <table class="el-table" style="margin: 20px 0px">
              <tr>
                <th class="cell" border="1">类型</th>
                <th class="cell">操作</th>
              </tr>
              <tr v-for="(item, index) in conditlist" :key="item.key">
                <td class="cell">{{ item.name }}</td>
                <td class="cell">
                  <el-button-group>
                    <el-tooltip content="编辑" placement="top">
                      <el-button type="primary" size="mini" icon="el-icon-edit"
                        @click="editConditParam(index)"></el-button>
                    </el-tooltip>
                    <el-tooltip content="移动" placement="top">
                      <el-button type="success" size="mini" icon="el-icon-arrow-up"
                        @click="moveConditParam(index)"></el-button>
                    </el-tooltip>
                    <el-tooltip content="删除" placement="top">
                      <el-button type="warning" size="mini" icon="el-icon-delete"
                        @click="delConditParam(index)"></el-button>
                    </el-tooltip>
                  </el-button-group>
                </td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane label="宿舍介绍">
            <el-button type="primary" @click="
              dialogDorm = true;
            param = {};
            ">添加</el-button>
            <table class="el-table" style="margin: 20px 0px">
              <tr>
                <th class="cell" border="1">类型</th>
                <th class="cell">价格</th>
                <th class="cell">周期</th>
                <th class="cell">图片</th>
                <th class="cell">操作</th>
              </tr>
              <tr v-for="(item, index) in dormlist" :key="item.key">
                <td class="cell">{{ item.type }}</td>
                <td class="cell">{{ item.price }}</td>
                <td class="cell">{{ item.time }}</td>
                <td class="cell">
                  <el-image style="width: 75px" :src="domain + item.imgUrl"
                    :preview-src-list="[domain + item.imgUrl]"></el-image>
                </td>
                <td class="cell">
                  <el-button-group>
                    <el-tooltip content="编辑" placement="top">
                      <el-button type="primary" size="mini" icon="el-icon-edit" @click="editParam(index)"></el-button>
                    </el-tooltip>
                    <el-tooltip content="移动" placement="top">
                      <el-button type="success" size="mini" icon="el-icon-arrow-up"
                        @click="moveParam(index)"></el-button>
                    </el-tooltip>
                    <el-tooltip content="删除" placement="top">
                      <el-button type="warning" size="mini" icon="el-icon-delete" @click="delParam(index)"></el-button>
                    </el-tooltip>
                  </el-button-group>
                </td>
              </tr>
            </table>
          </el-tab-pane>
        </el-tabs>
        <!-- 提交事件按钮  -->
        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 地图选择管理 -->
    <el-dialog title="地址选择" :visible.sync="dialogMap" @opened="dialogMapOpen()">
      <el-form label-width="100px">
        <el-form-item label="搜索地址：">
          <el-input v-model="searchKey">
            <el-button type="primary" slot="append" icon="el-icon-search" @click="searchMap()">搜索</el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div id="mapContainer" style="height: 500px"></div>
      <div class="dialog-footer" slot="footer">
        <el-button type="success" @click="yesClickMap">确定</el-button>
        <el-button @click="dialogMap = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 宿舍 -->
    <el-dialog :title="form.id > 0 ? '编辑宿舍' : '添加宿舍'" :visible.sync="dialogDorm" @opened="dialogOpened1()">
      <el-form :model="param" ref="param" label-width="110px" :status-icon="true"
        @submit.native.prevent="submitParam(param)">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item prop="type" label="类型" :rules="[{ required: true, message: '不能为空' }]">
              <el-select v-model="param.type" placeholder="请选择宿舍类型" clearable>
                <el-option v-for="item in dormtypelist" :key="item.id" :value="item.name"
                  :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="price" label="价格" :rules="[{ required: true, message: '不能为空' }]">
              <el-input step="0.01" v-model="param.price" placeholder="请输入价格"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="图片" :rules="[{ required: true, message: '不能为空' }]">
          <div id="uploadBtn9" class="uploader uploader-warning"></div>
          <img id="relativeName" :src="domain + param.imgUrl"
            style="height: 40px; position: relative; left: 10px; top: 20px" v-if="param.imgUrl" />
        </el-form-item>

        <el-form-item label="周期" prop="time" :rules="[{ required: true, message: '不能为空' }]">
          <el-input v-model="param.time" placeholder="请输入周期"></el-input>
        </el-form-item>

        <el-form-item label="费用详情" prop="feeList" :rules="[{ required: true, message: '不能为空' }]">
          <el-input type="textarea" :rows="4" v-model="param.feeList" placeholder="请输入费用详情，换行隔开"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogDorm = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 申请条件 -->
    <el-dialog :title="form.id > 0 ? '编辑申请条件' : '添加申请条件'" :visible.sync="dialogCondition">
      <el-form :model="conditParam" ref="conditParam" label-width="110px" :status-icon="true"
        @submit.native.prevent="submitConditParam(conditParam)">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item prop="name" label="类型" :rules="[{ required: true, message: '不能为空' }]">
              <el-select v-model="conditParam.name" placeholder="请选择类型" clearable>
                <el-option v-for="item in condittypelist" :key="item.id" :value="item.name"
                  :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="条件内容" prop="content">
          <el-input type="textarea" :rows="4" v-model="conditParam.content" placeholder="请输入条件内容"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogCondition = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
var editorInstance = null;
export default {
  components: { VueUeditorWrap },
  name: 'shool',
  data() {
    return {
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },
      domain: url.getDomain(),
      query: {}, //对象
      param: {}, //宿舍对象
      conditParam: {}, //申请条件对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      dialogDorm: false,
      dialogCondition: false,
      arealist: [], //地区列表
      selectedOptions: [],
      naturelist: [], //学校性质
      labellist: [], //标签
      monthlist: [], //入学时间
      simglist: [], //小图
      bimglist: [], //大图
      dormlist: [], //宿舍列表
      dormtypelist: [], //宿舍类型
      condittypelist: [], //申请条件类型
      conditlist: [], //申请条件
      dialogMap: false,
      searchKey: "",
      videolist: [],
      componentKey: 0
    };
  },
  methods: {
    addDomainIfNeeded(item) {
      // 如果链接不包含 'http'，则添加域名
      if (!item.includes('http')) {
        console.log('123', `${this.domain}${item}`);
        // this.updateComponent();
        return `${this.domain}${item}`;

      }
      return item;
    },

    delhref(index) {
      this.videolist.splice(index, 1);
      this.updateComponent();
      this.$message({
        message: '删除成功',
        type: 'warning'
      });
    },
    editorReady(instance) {
      editorInstance = instance;
    },
    //添加标题
    addTitle() {
      var title = '<h3 style="box-sizing: border-box; margin-top: 0px; margin-right: 0px; margin-left: 0px; line-height: 16px; font-family: &quot;Microsoft YaHei,Arial,Helvetica,sans-serif&quot;; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px !important; color: rgb(56, 57, 56) !important; font-size: 16px !important; border-bottom: 1px solid rgb(217, 217, 217) !important;"><strong style="box-sizing: border-box; display: inline-block; padding: 14px 0px; border-bottom: 2px solid rgb(46, 174, 240); margin-bottom: -1px;">标题内容</strong></h3>';


      title = editorInstance.getContent() + title;

      editorInstance.setContent(title);
      editorInstance.focus();
    },
    //删除上传的视频
    delidx(index) {
      this.videoUrlsArray.splice(index, 1);
      $message({
        type: 'success',
        message: '删除成功'
      })
      this.updateComponent();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    // 获取学校性质
    getSchNature: function () {
      var _this = this;
      var link = url.getSchool("getschnature");
      $.get(link, {}, (data) => {
        _this.naturelist = data;
      });
    },
    // 获取标签函数
    getSchLabelList: function () {
      var _this = this;
      var modelid = 5;
      var link = url.getSys_BasicData("GetBaseTree");
      $.get(link, { modelid: modelid }, (data) => {
        _this.labellist = data;
      });
    },
    //获取入学时间
    getMonthList: function () {
      var _this = this;
      var link = url.getSchool("GetMonthList");
      $.get(link, {}, (data) => {
        _this.monthlist = data;
      });
    },
    // 获取地区
    getJapAreaList: function () {
      var _this = this;
      var link = url.getCommon("getarea_Japan"); // 1 C# 接口的方法
      $.get(link, {}, (data) => {
        _this.arealist = data;
      });
    },
    // 获取语言学校宿舍类型
    getSchDormTypeList: function () {
      var _this = this;
      var link = url.getSchool("GetDormType");
      $.get(link, {}, (data) => {
        _this.dormtypelist = data;
      });
    },
    //获取语言学校申请条件类型
    getSchConditionType() {
      var _this = this;
      var link = url.getSchool("GetSchoolConditionType");
      $.get(link, {}, (data) => {
        _this.condittypelist = data;
      });
    },
    //查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.type = 1;
      var link = url.getSchool("getlist_school");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    // 上传
    dialogOpened: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn6 input").length == 0) {
        $("#uploadBtn6").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          type: "dialog",
          coverParams: {
            targetWidth: 400,
            targetHeight: 240,
            // itemWidth: 700,
            // itemheight:500,
          },
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });
      }

      // 上传视频

      $("#uploadBtn7").uploader({
        text: "上传视频",
        type: 'single',
        fileExts: "mp4",
        uploadType: 3,
        debug: true,
        maxSize: 1024 * 1024 * 1024, //上传文件最大值
        //开始上传验证扩展
        onSuccess: function (data) {
          console.log('data:', data);
          var relativeName1 = data.absoluteName; //解决其他js时间中的vue数据同步问题
          _this.videolist.push(data.absoluteName);
          console.log('_this.videolist:', _this.videolist);
          //  _this.$set(_this.form, "videoUrl", relativeName1);
        },
      });


      //   上传大小图
      if ($("#uploadSchool1 input").length == 0) {
        $("#uploadSchool1").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          more: true, //是否可以上传多个
          auto: true, //是否自动上传
          type: "imgdouble",
          minWidth: 600,
          minHeight: 600,
          maxWidth: 1200,
          maxHeight: 1200,
          handleType: "0", //对于大小图模式最好不要自动处理
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var small = data.small;
            var big = data.big;
            //增加上传处理后台处理
            var data = {
              imgSmall: small.relativeName,
              imgBig: big.relativeName,
            };
            _this.simglist.push(_this.domain + data.imgSmall);
            _this.bimglist.push(_this.domain + data.imgBig);
          },
        });
      }
    },
    //编辑 函数
    editClick: function (row) {
      var _this = this;
      _this.dialogVisible = true;

      var link = url.getSchool("getmodel_school");
      var id = row.id;
      $.get(link, { id: id }, (data) => {
        _this.selectedOptions = [data.province, data.city];
        if (data.monthList) {
          data.monthList = data.monthList.split(",");
        }
        if (data.labelList) {
          data.labelList = data.labelList.split(",");
        }
        if (data.imgSmallList) {
          _this.simglist = data.imgSmallList.split(",");
        }
        if (data.imgBigList) {
          _this.bimglist = data.imgBigList.split(",");
        }
        if (data.dormContent) {
          _this.dormlist = JSON.parse(data.dormContent);
        } else {
          _this.dormlist = [];
        }
        if (data.condition) {
          _this.conditlist = JSON.parse(data.condition);
        } else {
          _this.dormlist = [];
        }
        _this.form = data;
        _this.videolist = data.videoUrl.split(',')
      });
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getSchool("save_school");
      var info = this.form;
      info.videoUrl = this.videolist.join(',');
      console.log('info:', info);
      info.type = 1;
      info.province = _this.selectedOptions[0];
      info.city = _this.selectedOptions[1];
      if (info.monthList) {
        info.monthList = info.monthList.toString();
      }
      if (_this.simglist) {
        info.imgSmallList = _this.simglist.toString();
      }
      if (_this.bimglist) {
        info.imgBigList = _this.bimglist.toString();
      }
      if (info.labelList) {
        info.labelList = info.labelList.toString();
      }
      if (_this.dormlist) {
        info.dormContent = JSON.stringify(_this.dormlist);
      }
      if (_this.conditlist) {
        info.condition = JSON.stringify(_this.conditlist);
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var id = row.id;
      var link = url.getSchool("delete_school"); //获取请求地址

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /*********************地图代码************ */
    //地图框显示
    dialogMapOpen() {
      var _this = this;
      // 选择坐标处理
      var map;
      var marker;
      var citylocation;
      var siteLatitude = _this.form.latitude;
      if (!siteLatitude) siteLatitude = 39.916527;
      var siteLongitude = _this.form.longitude;
      if (!siteLongitude) siteLongitude = 116.397128;
      var init = function () {
        var center = new qq.maps.LatLng(siteLatitude, siteLongitude);
        map = new qq.maps.Map(document.getElementById("mapContainer"), {
          center: center,
          zoom: 13,
        });
        //获取城市列表接口设置中心点
        citylocation = new qq.maps.CityService({
          complete: function (result) {
            var startLatLng = result.detail.latLng;
            if (_this.form.latitude) {
              startLatLng = new qq.maps.LatLng(
                _this.form.latitude,
                _this.form.longitude
              );
            }
            map.setCenter(startLatLng);
            _this.latLng = startLatLng;

            //添加标记
            marker = new qq.maps.Marker({
              position: startLatLng,
              draggable: true,
              map: map,
            });
            _this.marker = marker;
            //添加到提示窗
            var info = new qq.maps.InfoWindow({
              map: map,
            });
            qq.maps.event.addListener(marker, "mouseup", function (e) {
              //获取经纬度 e.latLng
              //获取坐标 e.cursorPixel
              info.open();
              info.setContent(
                '<div style="text-align:center;white-space:nowrap;' +
                'margin:10px;">坐标：' +
                e.latLng.lat +
                "," +
                e.latLng.lng +
                "</div>"
              );
              info.setPosition(e.latLng);

              _this.latLng = e.latLng;
            });
          },
        });
        //调用searchLocalCity();方法    根据用户IP查询城市信息。
        citylocation.searchLocalCity();

        //处理搜索逻辑
        _this.map = map;
        _this.searchKey = _this.form.siteDetail;
        if (!_this.form.latitude && _this.searchKey) {
          setTimeout(() => {
            _this.searchMap();
          }, 500);
        }
      };
      init();
    },
    //确定按钮
    yesClickMap() {
      this.form.latitude = this.latLng.lat;
      this.form.longitude = this.latLng.lng;
      this.dialogMap = false;
    },
    //搜索按钮
    searchMap() {
      var _this = this;
      //执行搜索操作
      var searchService = new qq.maps.SearchService({
        complete: function (results) {
          var res = results.detail;
          if (res.pois && res.pois.length > 0) {
            var latLng = res.pois[0].latLng;
            _this.map.setCenter(latLng);
            _this.marker.setPosition(latLng);
          }
        },
      });
      searchService.search(this.searchKey);
    },

    /********************************* 学校组图 ********************************* */
    //移动
    imgMove(index) {
      var slist = Object.assign([], this.simglist);
      var blist = Object.assign([], this.bimglist);
      var sabove = index - 1;
      if (sabove == -1) {
        sabove = slist.length - 1;
      }
      var stemp = slist[index];
      slist[index] = slist[sabove];
      slist[sabove] = stemp;
      this.simglist = slist;

      var babove = index - 1;
      if (babove == -1) {
        sabove = slist.length - 1;
      }
      var btemp = blist[index];
      blist[index] = blist[babove];
      blist[babove] = btemp;
      this.bimglist = blist;
    },
    //删除
    imgRemove(index) {
      var _this = this;
      _this.simglist.splice(index, 1);
      _this.bimglist.splice(index, 1);
    },

    /********************************* 宿舍介绍 ********************************* */
    //编辑
    editParam(index) {
      var list = this.dormlist;

      var strList = [];
      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        strList.push(item);
      }
      this.param = strList[index];
      this.dialogDorm = true;
    },
    //删除
    delParam(index) {
      var list = this.dormlist;
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除操作
          list = list.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //移动
    moveParam(index) {
      var list = Object.assign([], this.dormlist);
      var temp = list[index];
      var above = index - 1;
      if (above == -1) {
        above = list.length - 1;
      }
      list[index] = list[above];
      list[above] = temp;
      this.dormlist = list;
    },
    // 上传
    dialogOpened1: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn9 input").length == 0) {
        $("#uploadBtn9").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          type: "dialog",
          coverParams: {
            targetWidth: 300,
            targetHeight: 200,
          },
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.param, "imgUrl", data.relativeName);
          },
        });
      }
    },
    // /提交
    submitParam(form) {
      var _this = this;
      this.$refs.param.validate((valid) => {
        if (valid) {
          //添加到参数列表
          var result = Object.assign({}, _this.param);
          var list = _this.dormlist;
          var index = -1;
          for (var i = 0; i < list.length; i++) {
            if (_this.dormlist[i].type == result.type) {
              index = i;
            }
          }
          if (index == -1) {
            _this.dormlist.push(result);
          } else {
            _this.dormlist[index] = result;
          }
          _this.dialogDorm = false;
        } else {
          return false;
        }
      });
    },
    updateComponent() {
      this.componentKey += 1;
    },

    /********************************* 申请条件 ********************************* */
    //编辑
    editConditParam(index) {
      console.log('form.videourl', this.form.videoUrl);
      var list = this.conditlist;




      var strList = [];
      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        strList.push(item);
      }
      this.conditParam = strList[index];
      this.dialogCondition = true;
    },
    //删除
    delConditParam(index) {
      var list = this.conditlist;
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除操作
          list = list.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //移动
    moveConditParam(index) {
      var list = Object.assign([], this.conditlist);
      var temp = list[index];
      var above = index - 1;
      if (above == -1) {
        above = list.length - 1;
      }
      list[index] = list[above];
      list[above] = temp;
      this.conditlist = list;
    },
    // /提交
    submitConditParam(form) {
      var _this = this;
      this.$refs.conditParam.validate((valid) => {
        if (valid) {
          //添加到参数列表
          var index = -1;
          var result = Object.assign({}, _this.conditParam);

          console.log('result:', result);
          console.log('conditlist:', _this.conditlist);
          var list = _this.conditlist;
          for (var i = 0; i < list.length; i++) {
            if (list[i].name == result.name) {
              index = i;
            }
          }
          console.log("index:", index);
          if (index == -1) {
            _this.conditlist.push(result);
          } else if (index != -1) {
            _this.conditlist[index] = result;
          } else {
            _this.conditlist.push(result);
          }

          _this.conditParam = {};
          _this.videolist = [];
          _this.dialogCondition = false;
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    videoUrlsArray() {
      if (this.form && typeof this.form.videoUrl === 'string') {

        return this.form.videoUrl.split(',');
      } else {

        return [];
      }
    }
  },
  watch: {
    videolist: {
      handler: function (newVal, oldVal) {
        // 在数组长度发生变化时触发的逻辑
        console.log('数组长度发生变化');
        // 上传视频
        if ($("#uploadBtn7 input").length == 0) {
          console.log("事件触发");
          $("#uploadBtn7").uploader({
            url: url.getUpload(),
            text: "上传视频",
            type: 'single',
            fileExts: "mp4",
            handleType: "0",
            uploadType: '3',
            oldFileName: '',
            more: false,
            debug: true,
            maxSize: 1024 * 1024 * 1024, //上传文件最大值
            //开始上传验证扩展
            onSuccess: function (data) {
              console.log('data:', data);
              var relativeName1 = data.relativeName; //解决其他js时间中的vue数据同步问题
              _this.videolist.push(data.relativeName);
              console.log('_this.videolist:', _this.videolist);
              //  _this.$set(_this.form, "videoUrl", relativeName1);
            },
          });
        }

        this.updateComponent();

      },
      deep: true
    }
  },
  mounted() {
    //   学校性质
    this.getSchNature();
    // 学校标签
    this.getSchLabelList();
    // 入学时间
    this.getMonthList();
    // 获取日本地区
    this.getJapAreaList();
    // 获取语言学校宿舍类型
    this.getSchDormTypeList();
    // 获取语言学校申请条件类型
    this.getSchConditionType();
    //获取服务器列表
    this.getList();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}

.el-form-item--small.el-form-item {
  margin-right: 0px;
}

.el-button--primary {
  margin-left: 10px;
}

.el-table td {
  border-bottom: none;
}

.el-image-viewer__wrapper {
  z-index: 2065 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: orange;
}
</style>
